@import url('../node_modules/searchkit/release/theme.css');

.my-logo {
  color: #fff;
  flex:0 0 300px;
  padding: 0;
  margin-right:15px;
  line-height: 16px;
}

.my-logo img {
  float: left;
  display: block;
  margin: 5px 0 0 0;
  padding-right: 10px;
}

.my-logo h1 {
  font-size: 12pt;
}

.sk-hits-grid, .sk-hits-list {
  margin: 0;
  margin-left: -15px;
}

.sk-top-bar {
  background-color: #2d2d2d;
}

.sk-select select {
  color: #373737;
  font-weight: 450;
}

.sk-layout__filters {
  flex: 0 0 300px;
}

.sk-panel__header {
  color: #373737;
}

.sk-item-list-option {
  color: #585858;
}

.sk-item-list-option__count {
  color: #838383;
}

.sk-hits-grid__item {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  margin: 0px;
  padding: 15px;
}

.sk-hits-grid-hit {
  margin: 15px;
  max-width: 350px;
  flex: 350px 0;
  color: #373737;
}

.sk-hits-grid-hit__poster {
  width: 100%;
}

.sk-hits-grid-hit__title {
  font-size: 25px;
  margin-bottom: 10px;
}

.image-bg {
  height: 200px;
  background-size: cover;
  position: relative;
}

.image-bg .sk-hits-grid-hit__title {
  position: absolute;
  bottom: 0;
  margin: 0;
  padding: 5px;
}

.af-chapter {
  /* font-size: 12px; */
  font-weight: 600;
}

.af-date {
  /* font-size: 12px; */
  font-weight: 600;
  /* font-style: italic; */
  margin-bottom: 10px;
}

.sk-hits-grid-hit em {
  background: #ff4b7e;
  color: #fff;
  font-style: normal;
  padding: 2px;
  line-height: 16pt;
}

.sk-hits-grid-hit a {
  color: #3e99ea;
}

.sk-reset-filters {
  color: #3e99ea;
}

.sk-hits-grid-hit__description {
  overflow-wrap: break-word;
}

.sk-layout__results {
  background-color: #f4f4f4;
  box-shadow: initial;
  padding-top: 0;
}

.sk-action-bar {
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.08);
  margin: 0;
  padding: 15px;
}

.rc-slider-tooltip {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

.rc-slider-mark {
  left: 30px;
  width: 200px;
}